<template>
    <div class="flex flex-col justify-center items-center h-32
            font-nunito font-black">
        <h1 v-if="loading">Loading ...</h1>
        <div v-else>
            <h1>
                {{ receipt.status === 'paid' ?
                    'Payment Successful' :
                    'Payment Pending' }}
            </h1>
            <h1>You will receive an email with the confirmation and invoice once we have reconfirmed payment information
                from the payment intermediary. This normally takes 10 - 15 minutes.</h1>
        </div>
    </div>
</template>

<script>

import api from '../models/api';

export default {
    props: ["receiptId"],
    data() {
        return {
            receipt: {},
            loading: true
        }
    },
    created() { this.loadData(); },

    methods: {
        async loadData() {
            this.receipt = (await api.get(`/receipts/${this.receiptId}`)).data;
            this.loading = false;
        }
    }
}
</script>
